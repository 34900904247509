import {
  GET_MEN_BASIC_INFO,
  GET_MEN_PRICE_RANGE,
  GET_MEN_STYLE_FIT,
  GET_MEN_STYLE_CUSTOM,
  GET_SCHEDULE,
  GET_PROMOCODE,
  GET_GIFTCARD,
  GET_ALL_ADDRESS,
  GET_WOMEN_BASIC_INFO,
  GET_WOMEN_STYLE_FIT,
  GET_WOMEN_PRICE_RANGE,
  GET_WOMEN_STYLE_CUSTOM,
  GET_CLIENT_PRODUCTS,
  SET_SHIPPING_ADDRESS_ID
} from 'actions/common/types';

const initialState = {
  loading: true,
  mBasicInfo: null,
  mStyleFit: null,
  mPriceRange: null,
  mStyleCustom: null,
  schedule: null,
  allAddress: [],
  shippingAddressId: null,
  wBasicInfo: null,
  wStyleFit: null,
  wPriceRange: null,
  wStyleCustom: null,
  products: [],
  paidStatus: 0,
  giftcode: [],
  giftcodePrice: 0,
  promocodes: [],
  promocodePrice: 0
};

const profileReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MEN_BASIC_INFO:
      return {
        ...state,
        mBasicInfo: payload,
        loading: false
      };
    case GET_MEN_STYLE_FIT:
      return {
        ...state,
        mStyleFit: payload,
        loading: false
      };
    case GET_MEN_PRICE_RANGE:
      return {
        ...state,
        mPriceRange: payload,
        loading: false
      };
    case GET_MEN_STYLE_CUSTOM:
      return {
        ...state,
        mStyleCustom: payload,
        loading: false
      };
    case GET_SCHEDULE:
      return {
        ...state,
        schedule: payload,
        styleFee: payload.styleFee,
        loading: false
      };
    case GET_GIFTCARD:
      const giftcodePrice = Array.isArray(payload)
        ? payload.reduce((total, gift) => total + parseFloat(gift.price || 0), 0)
        : 0;
      return {
        ...state,
        giftcode: Array.isArray(payload)
          ? payload.map((gift) => ({
              id: gift.id,
              user_id: gift.user_id,
              giftcode: gift.giftcode,
              price: gift.price,
              apply_dt: gift.apply_dt
            }))
          : [],
        giftcodePrice
      };
    case GET_PROMOCODE:
      const promocodePrice = Array.isArray(payload)
        ? payload.reduce((total, promo) => total + parseFloat(promo.price || 0), 0)
        : 0;
      return {
        ...state,
        promocodes: Array.isArray(payload)
          ? payload.map((promo) => ({
              id: promo.id,
              user_id: promo.user_id,
              promocode: promo.promocode,
              price: promo.price,
              apply_dt: promo.apply_dt
            }))
          : [],
        promocodePrice
        // totalPrice: promocodePrice + state.giftcodePrice
      };
    case GET_WOMEN_BASIC_INFO:
      return {
        ...state,
        wBasicInfo: payload,
        loading: false
      };
    case GET_WOMEN_STYLE_FIT:
      return {
        ...state,
        wStyleFit: payload,
        loading: false
      };
    case GET_WOMEN_PRICE_RANGE:
      return {
        ...state,
        wPriceRange: payload,
        loading: false
      };
    case GET_WOMEN_STYLE_CUSTOM:
      return {
        ...state,
        wStyleCustom: payload,
        loading: false
      };

    case GET_ALL_ADDRESS:
      return {
        ...state,
        allAddress: payload
      };
    case SET_SHIPPING_ADDRESS_ID:
      return {
        ...state,
        shippingAddressId: payload
      };
    case GET_CLIENT_PRODUCTS:
      return {
        ...state,
        products: payload.products,
        paidStatus: payload.paidStatus,
        productReview: payload.customerReview
      };
    default:
      return state;
  }
};

export default profileReducer;
